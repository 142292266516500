import React from "react";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { Fade } from "react-reveal";
import "./Resume.scss";
import { greeting } from "../../portfolio";
import { getFilePlugin } from "@react-pdf-viewer/get-file";

const Resume = () => {
  const darkPref = window.matchMedia("(prefers-color-scheme: dark)");
  const [isDark, setIsDark] = useLocalStorage("isDark", darkPref.matches);

  const getFilePluginInstance = getFilePlugin();
  const { DownloadButton } = getFilePluginInstance;

  const changeTheme = () => {
    setIsDark(!isDark);
  };

  return (
    <div>
      <Fade bottom duration={1000} distance="40px">
        <div className={isDark ? "dark-mode" : ""}>
          <div className="greet-main" id="">
            <div className="greeting-main">
              <Worker workerUrl={greeting.workerUrl}>
                {/* <Viewer
                  fileUrl="https://ahsanalamgir.com/resume/Ahsan%20Alamgir%20--%20Senior%20Software%20Engineer%20Resume%20Oct%202024.pdf"
                /> */}
                <div
                  className="rpv-core__viewer"
                  style={{
                    border: "1px solid rgba(0, 0, 0, 0.3)",
                    display: "flex",
                    flexDirection: "column",
                    height: "100%"
                  }}
                >
                  <div
                    style={{
                      alignItems: "center",
                      backgroundColor: "#eeeeee",
                      borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
                      display: "flex",
                      padding: "4px"
                    }}
                  >
                    <DownloadButton />
                  </div>
                  <div
                    style={{
                      flex: 1,
                      overflow: "hidden"
                    }}
                  >
                    <Viewer
                      fileUrl={require("../../assets/resume/" +
                        greeting.resumeLink)}
                      plugins={[getFilePluginInstance]}
                    />
                  </div>
                </div>
              </Worker>
            </div>
          </div>
        </div>
      </Fade>
    </div>
  );
};

export default Resume;
