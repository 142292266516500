import React, { useContext, useEffect, useState } from "react";
import "./Testimonials.scss";
import { clientTestimonials } from "../../portfolio";
import { Fade } from "react-reveal";
import StyleContext from "../../contexts/StyleContext";
import { API, BACKEND_URL } from "../../http-service";
import { Rating } from "primereact/rating";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import DetailDialogue from "../../components/detailDialogue/DetailDialogue";
import { Carousel } from "primereact/carousel";
import { Tag } from "primereact/tag";
import ImgsViewer from "react-images-viewer";
import TextTruncate from "../../helpers/TextTruncate";

export default function Testimonials() {
  function openDetails(testimonial) {
    setIsVisible(true);
    setSelectedTestimonial(testimonial);
    console.log("id: ", testimonial.id);
  }
  function openUrlInNewTab(url) {
    if (!url) {
      return;
    }
    var win = window.open(url, "_blank");
    win.focus();
  }
  function getProjectImage(url) {
    if (!url) {
      return;
    } else return BACKEND_URL + url;
  }
  const openImageViewer = () => {
    setIsViewerOpen(true);
  };
  const closeViewer = () => {
    setIsViewerOpen(false);
  };

  const gotoPrevious = () => {
    setCurrImgIndex(currImgIndex - 1);
  };
  const goToThumbnail = () => {
    console.log("goToThumbnail: ", goToThumbnail);
    // setCurrImgIndex(index);
  };

  const gotoNext = () => {
    setCurrImgIndex(currImgIndex + 1);
  };

  const { isDark } = useContext(StyleContext);
  const [testimonials, setTestimonials] = useState([]);
  const [visible, setVisible] = useState(false);
  const [selectedTestimonial, setSelectedTestimonial] = useState({});
  const [isVisible, setIsVisible] = useState(false);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [currImgIndex, setCurrImgIndex] = useState(0);
  const [visibleCards, setVisibleCards] = useState(6);

  const loadMoreCards = () => {
    setVisibleCards(prevVisible => prevVisible + 6);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = {
          page: 1,
          rowsPerPage: 50
        };
        await API.getTestimonials(
          data,
          result => {
            if (result.status === 200) {
              console.log("result.testimonials: ", result.testimonials);
              setTestimonials(result.testimonials);
            } else {
              console.log("Error:", result.status);
            }
          },
          error => {
            console.error("Error:", error);
          }
        );
      } catch (error) {
        console.log("Error:", error);
      }
    };
    fetchData();
  }, []);

  if (!clientTestimonials.display) {
    return null;
  }

  return (
    <>
      <DetailDialogue
        header="Testimonial Details"
        position="center"
        visible={isVisible}
        setVisible={setIsVisible}
        instance={selectedTestimonial}
      />
      <Fade bottom duration={1000} distance="20px">
        <div className="main" id="testimonials">
          <div>
            <h1 className="skills-heading">{clientTestimonials.title}</h1>
            <p
              className={
                isDark
                  ? "dark-mode testimonial-subtitle"
                  : "subTitle testimonial-subtitle"
              }
            >
              {clientTestimonials.subtitle}
            </p>

            <div className="testimonials-container">
              {testimonials.slice(0, visibleCards).map((testimonial, i) => {
                return (
                  <div
                    key={i}
                    className={
                      isDark
                        ? "dark-mode testimonial-card testimonial-card-dark"
                        : "testimonial-card testimonial-card-light"
                    }
                  >
                    {testimonial.logo ? (
                      <div
                        className="testimonial-image"
                        onClick={openImageViewer}
                      >
                        <img
                          src={getProjectImage(testimonial.logo)}
                          alt={testimonial.content}
                          className="card-image"
                        ></img>
                      </div>
                    ) : null}
                    <div className="testimonial-detail">
                      <h6
                        className={
                          isDark ? "dark-mode card-title" : "card-title"
                        }
                      >
                        {testimonial.source_website} Testimonial
                      </h6>
                      <p
                        className={
                          isDark ? "dark-mode card-subtitle" : "card-subtitle"
                        }
                      >
                        {testimonial.short_desc}
                      </p>
                      <div className="flex justify-center items-center justify-content-center justify-items-center gap-2">
                        <div className="">
                          <Rating
                            className=""
                            value={testimonial.rating}
                            readOnly
                            cancel={false}
                          />
                        </div>
                        <span>({testimonial.rating})</span>
                      </div>
                      <div className="flex items-center justify-content-center justify-items-center gap-2">
                        {/* <span className="font-bold">Testimonial:</span> */}
                        {/* <div className="">{testimonial.content}</div> */}
                        <TextTruncate text={testimonial.content} />
                      </div>
                      <div className="mt-3">
                        <Button
                          raised
                          outlined
                          label="View Details"
                          onClick={() => openDetails(testimonial)}
                          size="small"
                        />
                      </div>
                      {/* <Button label="Click Me" /> */}

                      {testimonial.website_link ? (
                        <div className="testimonial-card-footer">
                          <span
                            key={i}
                            className={
                              isDark
                                ? "dark-mode testimonial-tag"
                                : "testimonial-tag"
                            }
                            onClick={() =>
                              openUrlInNewTab(
                                `${BACKEND_URL}storage/testimonial.website_link`
                              )
                            }
                          >
                            Visit Website
                          </span>
                        </div>
                      ) : null}
                    </div>
                  </div>
                );
              })}
            </div>
            {visibleCards < testimonials.length && (
              <div className="flex items-center justify-content-center p-4 animate__animated animate__pulse animate__infinite animate__delay-2s">
                <Button
                  onClick={loadMoreCards}
                  label="Load More Reviews"
                  raised
                />
              </div>
              // <button onClick={loadMoreCards} className="load-more-btn">
              //   Load More
              // </button>
            )}
          </div>
        </div>
        <ImgsViewer
          imgs={testimonials.map(t => ({
            src: getProjectImage(t.logo),
            thumbnail: getProjectImage(t.logo)
          }))}
          currImg={currImgIndex}
          isOpen={isViewerOpen}
          onClickPrev={gotoPrevious}
          onClickNext={gotoNext}
          onClose={closeViewer}
          // showThumbnails={true}
          // onClickThumbnail={goToThumbnail}
        />
      </Fade>
    </>
  );
}
