import React, { useState, useEffect, useContext, Suspense, lazy } from "react";
import "./WorkExperience.scss";
// import ExperienceCard from "../../components/experienceCard/ExperienceCard";
// import ExperienceCardDynamic from "../../components/experienceCard/ExperienceCardDynamic";
import { workExperiences } from "../../portfolio";
import { Fade } from "react-reveal";
import StyleContext from "../../contexts/StyleContext";
import { API } from "../../http-service";
import Loading from "../loading/Loading";

export default function WorkExperience() {
  const [experiences, setExperiences] = useState([]);
  const ExperienceCardDynamic = lazy(() =>
    import("../../components/experienceCard/ExperienceCardDynamic")
  );
  const renderLoader = () => <Loading />;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = {
          page: 1,
          rowsPerPage: 50
        };
        await API.getExperiences(
          data,
          result => {
            if (result.status === 200) {
              console.log("result.experiences: ", result.experiences);
              setExperiences(result.experiences);
            } else {
              console.log("Error:", result.status);
            }
          },
          error => {
            console.error("Error:", error);
          }
        );
      } catch (error) {
        console.log("Error:", error);
      }
    };
    fetchData();
  }, []);

  const { isDark } = useContext(StyleContext);
  if (workExperiences.display) {
    return (
      <Suspense fallback={renderLoader()}>
        <div className="experience-container" id="workExperience">
          <div>
            <h1 className="experience-heading">Experiences</h1>
            <div className="experience-cards-div">
              {/* {workExperiences.experience.map((card, i) => {
                  return (
                  <ExperienceCard
                      key={i}
                      isDark={isDark}
                      cardInfo={{
                        company: card.company,
                        desc: card.desc,
                        date: card.date,
                        companylogo: card.companylogo,
                        role: card.role,
                        descBullets: card.descBullets
                      }}
                    />
                  );
                })} */}
              {experiences.map((card, i) => {
                return (
                  <ExperienceCardDynamic
                    key={i}
                    isDark={isDark}
                    cardInfo={{
                      name: card.name,
                      logo: card.logo,
                      job_title: card.job_title,
                      start_date: card.start_date,
                      end_date: card.end_date,
                      short_desc: card.short_desc,
                      long_desc: card.long_desc,
                      color: card.color,
                      has_certificate: card.has_certificate,
                      cert_link: card.cert_link
                    }}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </Suspense>
    );
  }
  return null;
}
