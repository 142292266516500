// import React from "react";

// const StyleContext = React.createContext();

// export const StyleProvider = StyleContext.Provider;
// export const StyleConsumer = StyleContext.Consumer;

// export default StyleContext;

// import React, { createContext, useState } from "react";
// import { useLocalStorage } from "../hooks/useLocalStorage";

// const StyleContext = createContext();

// export const StyleProvider = ({ children }) => {
//   const darkPref = window.matchMedia("(prefers-color-scheme: dark)");
//   // const [isDark, setIsDark] = useState(darkPref);
//   const [isDark, setIsDark] = useLocalStorage("isDark", darkPref.matches);

//   const changeTheme = () => setIsDark((prev) => !prev);

//   return (
//     <StyleContext.Provider value={{ isDark, changeTheme }}>
//       {children}
//     </StyleContext.Provider>
//   );
// };

// export default StyleContext;

import React, { createContext, useContext, useState, useEffect } from "react";
import { useLocalStorage } from "../hooks/useLocalStorage";

const StyleContext = createContext();

export const useStyleContext = () => useContext(StyleContext);

export const StyleProvider = ({ children }) => {
  const darkPref = window.matchMedia("(prefers-color-scheme: dark)");
  // const [isDark, setIsDark] = useState(darkPref.matches);
  const [isDark, setIsDark] = useLocalStorage("isDark", darkPref.matches);

  const changeTheme = () => setIsDark(!isDark);

  useEffect(() => {
    darkPref.addEventListener("change", e => setIsDark(e.matches));
    return () =>
      darkPref.removeEventListener("change", e => setIsDark(e.matches));
  }, [darkPref]);

  return (
    <StyleContext.Provider value={{ isDark, changeTheme }}>
      {children}
    </StyleContext.Provider>
  );
};

export default StyleContext;
