import React, { createRef, useContext, useState } from "react";
import { Fade, Slide } from "react-reveal";
import "./DetailDialogue.scss";
import StyleContext from "../../contexts/StyleContext";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Avatar } from "primereact/avatar";
import { BACKEND_URL } from "../../http-service";
import { Rating } from "primereact/rating";

export default function DetailDialogue({
  header,
  position,
  visible,
  setVisible,
  instance
}) {
  const imgRef = createRef();

  const GetDescBullets = ({ descBullets }) => {
    return descBullets
      ? descBullets.map((item, i) => (
          <li key={i} className="subTitle">
            {item}
          </li>
        ))
      : null;
  };
  const { isDark } = useContext(StyleContext);

  const headerElement = (
    <>
      <div className="inline-flex align-items-center justify-content-center gap-2">
        <Avatar image={BACKEND_URL + instance.logo} shape="circle" />
        <span className="font-bold white-space-nowrap">
          {instance.client_name}
        </span>
        <div className="flex justify-center items-center justify-content-center justify-items-center gap-2">
          |{" "}
          <div className="flex">
            <Rating
              className=""
              value={instance.rating}
              readOnly
              cancel={false}
            />
          </div>
          <span>({instance.rating})</span>|{" "}
          <div className="flex">
            <img
              width={"80px"}
              src={require("../../assets/images/Fiverr-Logo.png")}
            />
          </div>
        </div>
      </div>
    </>
  );

  const footerContent = (
    <div>
      {/* <Button label="No" icon="pi pi-times" onClick={() => setVisible(false)} className="p-button-text" /> */}
      <Button
        label="Close"
        icon="pi pi-check"
        onClick={() => setVisible(false)}
        autoFocus
      />
    </div>
  );
  // const [visible, setVisible] = useState(false);

  // setVisible(show);

  // if (!instance.logo)
  //   console.error(`Image of ${instance.name} is missing in education section`);
  return (
    <div>
      {/* <Fade left duration={1000}> */}
      <Dialog
        position={position}
        visible={visible}
        modal
        header={headerElement}
        footer={footerContent}
        style={{ width: "40rem" }}
        draggable={false}
        onHide={() => {
          if (!visible) return;
          setVisible(false);
        }}
      >
        {/* <p>
          Client Name: {instance.client_name}
        </p> */}
        {/* <p>
          Ordered On: {instance.company_name}
        </p>
        <p>
          Review: {instance.content}
        </p> */}
        <blockquote>{instance.content}</blockquote>
      </Dialog>
      {/* <Dialog header={header} position={position} visible={visible} maximizable style={{ width: '50vw' }} draggable={false} onHide={() => { if (!visible) return; setVisible(false); }}
        footer={footerContent}>
      </Dialog> */}
      {/* </Fade> */}
      {/* <Slide left duration={2000}>
        <div className="education-card-border"></div>
      </Slide> */}
    </div>
  );
}
