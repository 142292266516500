import React, { useContext, useEffect } from "react";
import Greeting from "./greeting/Greeting";
import Skills from "./skills/Skills";
import StackProgress from "./skillProgress/skillProgress";
import WorkExperience from "./workExperience/WorkExperience";
import Projects from "./projects/Projects";
import StartupProject from "./StartupProjects/StartupProject";
import Testimonials from "./testimonials/Testimonials";
import Achievement from "./achievement/Achievement";
import Blogs from "./blogs/Blogs";
import Talks from "./talks/Talks";
import Podcast from "./podcast/Podcast";
import Education from "./education/Education";
import Twitter from "./twitter-embed/twitter";
import Profile from "./profile/Profile";
import { useLocalStorage } from "../hooks/useLocalStorage";
import StyleContext from "../contexts/StyleContext";
import { useLocation } from "react-router-dom";

const Home = () => {
  const darkPref = window.matchMedia("(prefers-color-scheme: dark)");
  // const [isDark, setIsDark] = useLocalStorage("isDark", darkPref.matches);
  const { isDark, changeTheme } = useContext(StyleContext);

  const { state, hash } = useLocation();
  const location = useLocation();
  const { targetId } = state || {};

  // useEffect(() => {
  //   const el = document.getElementById(targetId);
  //   const isHomeRoute = location.pathname === "/";
  //   console.log("isHomeRoute: ", isHomeRoute);
  //   if (el && !isHomeRoute) {
  //     el.scrollIntoView();
  //   }
  // }, [hash, targetId]);

  return (
    <div className={isDark ? "dark-mode" : null}>
      <>
        <Greeting />
        <Skills />
        <StackProgress />
        <Education />
        <WorkExperience />
        <Projects />
        <StartupProject />
        <Testimonials />
        <Achievement />
        <Blogs />
        <Talks />
        <Twitter />
        <Podcast />
        <Profile />
      </>
    </div>
  );
};

export default Home;
