import axios from "axios";
window.axios = axios;

const API_URL = process.env.REACT_APP_BACKEND_URL + "api/frontend/";
export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export const API = {
  getProjects(data, cb, errorCB) {
    axios
      .get(
        API_URL + `getProjects?page=${data.page}&rowsPerPage=${data.rowsPerPage}`
      )
      .then(resp => {
        cb(resp.data);
      })
      .catch(err => {
        errorCB(err.response);
      });
  },
  getTestimonials(data, cb, errorCB) {
    axios
      .get(
        API_URL +
          `getTestimonials?page=${data.page}&rowsPerPage=${data.rowsPerPage}`
      )
      .then(resp => {
        cb(resp.data);
      })
      .catch(err => {
        errorCB(err.response);
      });
  },
  getExperiences(data, cb, errorCB) {
    axios
      .get(
        API_URL +
          `getExperiences?page=${data.page}&rowsPerPage=${data.rowsPerPage}`
      )
      .then(resp => {
        cb(resp.data);
      })
      .catch(err => {
        errorCB(err.response);
      });
  },
  getCompanies(data, cb, errorCB) {
    axios
      .get(
        API_URL + `getCompanies?page=${data.page}&rowsPerPage=${data.rowsPerPage}`
      )
      .then(resp => {
        cb(resp.data);
      })
      .catch(err => {
        errorCB(err.response);
      });
  },
  getCompaniesList(cb, errorCB) {
    axios
      .get(API_URL + `companiesList`)
      .then(resp => {
        cb(resp.data);
      })
      .catch(err => {
        errorCB(err.response);
      });
  },
  // saveProject(data, cb, errorCB) {
  //   axios
  //     .post(API_URL + `projects`, data, {
  //       headers: { "Content-Type": "multipart/form-data" }
  //     })
  //     .then(resp => {
  //       cb(resp.data);
  //     })
  //     .catch(err => {
  //       errorCB(err.response);
  //     });
  // },
  // saveCompany(data, cb, errorCB) {
  //   axios
  //     .post(API_URL + `companies`, data, {
  //       headers: { "Content-Type": "multipart/form-data" }
  //     })
  //     .then(resp => {
  //       cb(resp.data);
  //     })
  //     .catch(err => {
  //       errorCB(err.response);
  //     });
  // },
  // deleteCompany(data, cb, errorCB) {
  //   axios
  //     .delete(API_URL + `companies/` + data.id)
  //     .then(resp => {
  //       cb(resp.data);
  //     })
  //     .catch(err => {
  //       errorCB(err.response);
  //     });
  // },
  // updateCompany(data, cb, errorCB) {
  //   axios
  //     .post(API_URL + `companies/` + data.id, data, {
  //       headers: { "Content-Type": "multipart/form-data" }
  //     })
  //     .then(resp => {
  //       cb(resp.data);
  //     })
  //     .catch(err => {
  //       errorCB(err.response);
  //     });
  // },

  // Employees endpoints
  getEmployees(data, cb, errorCB) {
    axios
      .get(
        API_URL + `getEmployees?page=${data.page}&rowsPerPage=${data.rowsPerPage}`
      )
      .then(resp => {
        cb(resp.data);
      })
      .catch(err => {
        errorCB(err.response);
      });
  },
  // saveEmployee(data, cb, errorCB) {
  //   axios
  //     .post(API_URL + `employees`, data)
  //     .then(resp => {
  //       cb(resp.data);
  //     })
  //     .catch(err => {
  //       errorCB(err.response);
  //     });
  // },
  // deleteEmployee(data, cb, errorCB) {
  //   axios
  //     .delete(API_URL + `employees/` + data.id)
  //     .then(resp => {
  //       cb(resp.data);
  //     })
  //     .catch(err => {
  //       errorCB(err.response);
  //     });
  // },
  // updateEmployee(data, cb, errorCB) {
  //   axios
  //     .put(API_URL + `employees/` + data.id, data)
  //     .then(resp => {
  //       cb(resp.data);
  //     })
  //     .catch(err => {
  //       errorCB(err.response);
  //     });
  // }
};
