import React, { useContext } from "react";
import Headroom from "react-headroom";
import "./Header.scss";
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch";
import StyleContext from "../../contexts/StyleContext";
import {
  greeting,
  workExperiences,
  skillsSection,
  openSource,
  blogSection,
  talkSection,
  achievementSection
} from "../../portfolio";
import { useLocation, useNavigate } from "react-router-dom";
import ScrollIntoView from "react-scroll-into-view";
import ScrollIntoViewIfNeeded from "react-scroll-into-view-if-needed";

function Header() {
  const { isDark, changeTheme } = useContext(StyleContext);
  const viewExperience = workExperiences.display;
  const viewOpenSource = openSource.display;
  const viewSkills = skillsSection.display;
  const viewAchievement = achievementSection.display;
  const viewBlog = blogSection.display;
  const viewTalks = talkSection.display;
  const navigate = useNavigate();
  const location = useLocation();

  const handleViewScroll = id => {
    console.log("id: ", id);
    navigate(`/#${id}`);

    const isHomeRoute = location.pathname === "/";
    if (!isHomeRoute) {
      setTimeout(() => {
        const element = document.getElementById(id);
        console.log("element: ", element);
        if (element) {
          element.scrollIntoView({
            behavior: "smooth",
            block: "start"
          });
        }
      }, 1000);
    }
  };

  return (
    <Headroom>
      <header className={isDark ? "dark-menu header" : "header"}>
        <a href="/" onClick={() => navigate("/")} className="logo">
          <span className="grey-color"> &lt;</span>
          <span className="logo-name">{greeting.username}</span>
          <span className="grey-color">/&gt;</span>
        </a>
        <input className="menu-btn" type="checkbox" id="menu-btn" />
        <label
          className="menu-icon"
          htmlFor="menu-btn"
          style={{ color: "white" }}
        >
          <span className={isDark ? "navicon navicon-dark" : "navicon"}></span>
        </label>
        <ul className={isDark ? "dark-menu menu" : "menu"}>
          {viewSkills && (
            <li>
              <ScrollIntoView selector="#skills">
                <a
                  href="javascript:void(0)"
                  onClick={() => handleViewScroll("skills")}
                >
                  Skills
                </a>
              </ScrollIntoView>
            </li>
          )}
          {viewExperience && (
            <li>
              <ScrollIntoView selector="#workExperience">
                <a
                  href="javascript:void(0)"
                  onClick={() => handleViewScroll("workExperience")}
                >
                  Experiences
                </a>
              </ScrollIntoView>
            </li>
          )}
          {viewOpenSource && (
            <li>
              <ScrollIntoView selector="#testimonials">
                <a
                  href="javascript:void(0)"
                  onClick={() => handleViewScroll("testimonials")}
                >
                  Testimonials
                </a>
              </ScrollIntoView>
            </li>
          )}
          {viewOpenSource && (
            <li>
              <ScrollIntoView selector="#opensource">
                <a
                  href="javascript:void(0)"
                  onClick={() => handleViewScroll("opensource")}
                >
                  Open Source
                </a>
              </ScrollIntoView>
            </li>
          )}
          {viewAchievement && (
            <li>
              <ScrollIntoView selector="#achievements">
                <a
                  href="javascript:void(0)"
                  onClick={() => handleViewScroll("achievements")}
                >
                  Certifications
                </a>
              </ScrollIntoView>
            </li>
          )}
          {viewBlog && (
            <li>
              <ScrollIntoView selector="#blogs">
                <a
                  href="javascript:void(0)"
                  onClick={() => handleViewScroll("blogs")}
                >
                  Blogs
                </a>
              </ScrollIntoView>
            </li>
          )}
          {viewTalks && (
            <li>
              <ScrollIntoView selector="#talks">
                <a
                  href="javascript:void(0)"
                  onClick={() => handleViewScroll("talks")}
                >
                  Talks
                </a>
              </ScrollIntoView>
            </li>
          )}
          <li>
            <ScrollIntoView selector="#contact">
              <a
                href="javascript:void(0)"
                onClick={() => handleViewScroll("contact")}
              >
                Contact Me
              </a>
            </ScrollIntoView>
          </li>
          <li>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a>
              <ToggleSwitch />
            </a>
          </li>
        </ul>
      </header>
    </Headroom>
  );
}
export default Header;
