import React, { useContext, useEffect, useState } from "react";
import "./StartupProjects.scss";
import { bigProjects } from "../../portfolio";
import { Fade } from "react-reveal";
import StyleContext from "../../contexts/StyleContext";
import { API, BACKEND_URL } from "../../http-service";
import { Button } from "primereact/button";

export default function StartupProject() {
  function openUrlInNewTab(url) {
    if (!url) {
      return;
    }
    const validUrl =
      url.startsWith("http://") || url.startsWith("https://")
        ? url
        : `https://${url}`;

    var win = window.open(validUrl, "_blank");
    win.focus();
  }
  function getProjectImage(url) {
    if (!url) {
      return;
    } else return BACKEND_URL + url;
  }

  const { isDark } = useContext(StyleContext);
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = {
          page: 1,
          rowsPerPage: 50
        };
        await API.getProjects(
          data,
          result => {
            if (result.status === 200) {
              console.log("result.projects: ", result.projects);
              setProjects(result.projects);
            } else {
              console.log("Error:", result.status);
            }
          },
          error => {
            console.error("Error:", error);
          }
        );
      } catch (error) {
        console.log("Error:", error);
      }
    };
    fetchData();
  }, []);

  if (!bigProjects.display) {
    return null;
  }

  return (
    <Fade bottom duration={1000} distance="20px">
      <div className="main" id="projects">
        <div>
          <h1 className="skills-heading">{bigProjects.title}</h1>
          <p
            className={
              isDark
                ? "dark-mode project-subtitle"
                : "subTitle project-subtitle"
            }
          >
            {bigProjects.subtitle}
          </p>

          <div className="projects-container">
            {projects.map((project, i) => {
              return (
                <div
                  key={i}
                  className={
                    isDark
                      ? "dark-mode project-card project-card-dark"
                      : "project-card project-card-light"
                  }
                >
                  {project.logo ? (
                    <div className="project-image">
                      <img
                        src={getProjectImage(project.logo)}
                        alt={project.name}
                        className="card-image"
                      ></img>
                    </div>
                  ) : null}
                  <div className="project-detail">
                    <h5
                      className={isDark ? "dark-mode card-title" : "card-title"}
                    >
                      {project.name}
                    </h5>
                    <p
                      className={
                        isDark ? "dark-mode card-subtitle" : "card-subtitle"
                      }
                    >
                      {project.short_desc}
                    </p>
                    {project.website_link ? (
                      <div className="project-card-footer animate__animated animate__pulse animate__infinite animate__delay-2s">
                        <Button
                          raised
                          outlined
                          label="Visit Website"
                          onClick={() =>
                            openUrlInNewTab(`${project.website_link}`)
                          }
                          size="small"
                          className={
                            isDark ? "dark-mode project-tag" : "project-tag"
                          }
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Fade>
  );
}
