import React, { useContext, useEffect, useState } from "react";
import { Fade } from "react-reveal";
import emoji from "react-easy-emoji";
import "./Greeting.scss";
import landingPerson from "../../assets/lottie/landingPerson";
import DisplayLottie from "../../components/displayLottie/DisplayLottie";
import SocialMedia from "../../components/socialMedia/SocialMedia";
// import Button from "../../components/button/Button";
import { Button } from "primereact/button";

import { illustration, greeting } from "../../portfolio";
import StyleContext from "../../contexts/StyleContext";
import { useNavigate } from "react-router-dom";
import Top from "../topbutton/Top";
import TopEvent from "../topbutton/Top";

export default function Greeting() {
  const { isDark } = useContext(StyleContext);
  const [imageSrc, setImageSrc] = useState(
    require("../../assets/images/cropped_image-white2.png")
  );
  const [animationClass, setAnimationClass] = useState("animate__fadeIn");
  const navigate = useNavigate();

  useEffect(() => {
    // Apply fade-out effect first, then change image and fade-in
    setAnimationClass("animate__fadeOutDownBig");
    // isDark? setImageSrc(newImage) : null

    const newImage = isDark
      ? require("../../assets/images/cropped_image-bg.png")
      : require("../../assets/images/cropped_image-white2.png");
    setImageSrc(newImage);

    const timeout = setTimeout(() => {
      setAnimationClass("animate__fadeInDown");
    }, 1000);

    return () => clearTimeout(timeout);
  }, [isDark]);
  if (!greeting.displayGreeting) {
    return null;
  }

  const openResume = resumeLink => {
    if (!resumeLink) {
      return;
    }
    navigate("/resume");
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };
  return (
    <Fade bottom duration={1000} distance="40px">
      <div className={isDark ? "dark-mode" : null}>
        <div className="greet-main" id="greeting">
          <div className="greeting-main">
            <div className="greeting-text-div">
              <div>
                <h1
                  className={
                    isDark ? "dark-mode greeting-text" : "greeting-text"
                  }
                >
                  {" "}
                  {greeting.title}{" "}
                  <span className="wave-emoji">{emoji("👋")}</span>
                </h1>
                <p
                  className={
                    isDark
                      ? "dark-mode greeting-text-p"
                      : "greeting-text-p subTitle"
                  }
                >
                  {greeting.subTitle}
                </p>
                <SocialMedia />
                {/* <div className="button-greeting-div">
                  <Button text="Contact me" href="#contact" />
                  {greeting.resumeLink && (
                    <Button
                      text="See my resume"
                      newTab={true}
                      href={greeting.resumeLink}
                    />
                  )}
                </div> */}
                <div className="button-greeting-div gap-4">
                  <Button
                    raised
                    label="Contact me"
                    onClick={() => (window.location.href = "#contact")}
                    size="lg"
                    className="animate__animated animate__pulse animate__infinite animate__delay-2s"
                  />
                  {greeting.resumePath && (
                    <Button
                      raised
                      label="View Resume"
                      onClick={() => openResume(greeting.resumePath)}
                      size="lg"
                      className="animate__animated animate__pulse animate__infinite animate__delay-2s"
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="greeting-image-div">
              {/* {illustration.animated ? (
                <DisplayLottie animationData={landingPerson} />
              ) : (
                // <img
                //   alt="man sitting on table"
                //   src={isDark? require("../../assets/images/cropped_image-bg.png"): require("../../assets/images/cropped_image-white2.png")}
                // ></img> */}
              <img
                alt="man sitting on table"
                src={imageSrc}
                className={`animate__animated ${animationClass}`}
                style={{ width: "100%", height: "auto" }}
              />
              {/* )} */}
            </div>
          </div>
        </div>
      </div>
    </Fade>
  );
}
